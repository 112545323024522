// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/other';

// 定义后台接口地址
const url = {
    weiboHotSearch: baseUrl + '/weibo/hotSearch',
}
// 对外导出操作方法
export function weiboHotSearch() {
    return request.get(url.weiboHotSearch);
}
