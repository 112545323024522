// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/base/obj';

// 定义后台接口地址
const url = {
}

// 对外导出操作方法
export function list(param) {
    return request.get(baseUrl, param);
}
export function getOne(uuid) {
    return request.get(baseUrl + "/" + uuid);
}
export function save(param) {
    return request.post(baseUrl, param);
}
export function del(param) {
    return request.post(baseUrl+"/del", param);
}
export function batchDel(param) {
    return request.post(baseUrl+"/del/batch", param);
}