// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/online';

// 定义后台接口地址
const url = {
    getOnlineUsers: baseUrl + '/users',
    getdownline: baseUrl + '/downline',
}

// 对外导出操作方法
//查询菜单树
export function getOnlineUsers() {
    return request.get(url.getOnlineUsers);
}

//强制下线单个用户
export function downline(token){
    return request.post(url.getdownline,token);
}