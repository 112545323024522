// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/sys/dept';

// 定义后台接口地址
const url = {
    getList: baseUrl + '/list',
    getTree: baseUrl + '/tree',
    getOne: baseUrl,
    save: baseUrl + '/save',
    del: baseUrl + '/del',
}

// 对外导出操作方法
export function getList(){
    return request.get(url.getList);
}export function getTree(){
    return request.get(url.getTree);
}
export function getOne(uuid){
    return request.get(url.getOne + "/" + uuid);
}
export function save(param){
    return request.post(url.save, param);
}
export function del(param){
    return request.post(url.del, param);
}