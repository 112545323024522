export default {

    // 深拷贝
    deepCopy(obj) {
        // 如果obj为null或者不是对象类型，则直接返回obj
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }
    
        // 初始化一个新对象，用于存放复制后的数据
        let copy = Array.isArray(obj) ? [] : {};
    
        // 遍历原对象的属性
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) { // 仅复制自身属性，不复制原型链上的属性
            // 如果属性值是对象类型，则递归调用deepCopy函数进行深拷贝，否则直接复制属性值
            copy[key] = typeof obj[key] === 'object' ? deepCopy(obj[key]) : obj[key];
            }
        }
        return copy;
    },

    // 线程等待
    sleep(time) {
        return new Promise(resolve => setTimeout(resolve, time))
    }
    
}