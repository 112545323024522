// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/upload';

// 定义后台接口地址
const url = {
    uploadFile: baseUrl + '/file',
}

// 对外导出操作方法
export function uploadFile(formData) {
    return request.upload(url.uploadFile, formData);
}