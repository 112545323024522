// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/base/obj/field';

// 定义后台接口地址
const url = {
}

// 对外导出操作方法
export function list(objCode, param) {
    return request.get(baseUrl + "/" + objCode, param);
}
export function one(objCode,uuid) {
    return request.get(baseUrl + "/" + objCode + "/" + uuid);
}
export function save(param) {
    return request.post(baseUrl, param);
}
export function del(param) {
    return request.post(baseUrl + "/del", param);
}