/** 状态配置 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    // 定义变量
    state: {
        permissions: {},
        dict: {},
        system: {
            name: "",
            developer: "",
        },
    },
    // 定义修改方法
    mutations: {
    },
});

export default store;