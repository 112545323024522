// HTTP 请求工具
// import {encode, decode} from 'js-base64'
import { Message, Loading } from "element-ui";
import router from "@/router";
import axios from "axios";
import {Encrypt, Decrypt} from '@/utils/aesUtil'

// 设置服务器基础地址
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// 设置默认头信息
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 跨域请求设置：是否携带cookie
axios.defaults.withCredentials = false;

// 请求超时时间
axios.defaults.timeout = 30000;

// let loading

// request 请求拦截器
axios.interceptors.request.use(
    request => {
        // loading = Loading.service({
        //     lock: true,
        //     text: "加载数据中~~~",
        //     spinner: "el-icon-loading",
        //     background: "rgba(0, 0, 0, 0.7)",
        //   });
        if(request.headers["Content-Type"] != "multipart/form-data" && (request.method=="post" || request.method=="put")){
            request.data = {encrypted: Encrypt(request.data)}
        }
        const token = "Bearer " + sessionStorage.token;
        token && (request.headers.Authorization = token);
        return request;
    },
    error => {
        return Promise.error(error);
    }
);

// response 响应拦截器
axios.interceptors.response.use(
    response => {
        sessionStorage.setItem("LOGIN_FAILURE", false);
        // loading.close();
        if (response.status === 200) {
            response.data = JSON.parse(Decrypt(response.data.encrypted));
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务状态码不是200的情况
    error => {
        // loading.close();
        if (error.response.status) {
            switch (error.response.status) {
                // 401 未登录
                // 登录过期对用户进行提示
                // 清除本地token
                // 跳转登录页面
                case 401:
                    let login_failure = sessionStorage.getItem("LOGIN_FAILURE");
                    if(login_failure == 'true'){
                        break;
                    }
                    Message.error("登录失效，请重新登录！");
                    router.replace({
                        path: "/admin/login",
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    sessionStorage.removeItem("token");
                    sessionStorage.setItem("LOGIN_FAILURE", true);
                    break;
                case 404:
                    Message.error("404")
                    break;
                default:
                    Message.error("未知异常，请联系管理员！");
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);



export default {

    /**
     * get方法对应get请求
     * @param {String}url 请求地址
     * @param {Object}params携带参数
     * @returns {Promise}
     */
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                    params: params
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    /**
     * post方法对应post请求
     * @param {String} url 请求地址
     * @param {Object} params 携带参数
     * @returns {Promise}
     */
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, JSON.stringify(params), {headers: {'Content-Type': 'application/json;charset=UTF-8'}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    upload(url, formData) {
        return new Promise((resolve, reject) => {
            axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    put(url, params) {
        return new Promise((resolve, reject) => {
            axios.put(url, JSON.stringify(params), {headers: {'Content-Type': 'application/json;charset=UTF-8'}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    delete(url, params) {
        return new Promise((resolve, reject) => {
            axios.delete(url, JSON.stringify(params), {headers: {'Content-Type': 'application/json;charset=UTF-8'}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    download(url, params) {
        axios.post(url, JSON.stringify(params), {responseType: 'blob'})
            .then(res => {
                let contentDisposition = res.headers['content-disposition']
                let fileName = decodeURI(contentDisposition.substring(contentDisposition.indexOf("filename=")+9))
                let blob = res.data;
                //将请求的blob数据转为可下载的url地址
                let url = URL.createObjectURL(blob)
                // 创建一个下载标签<a>
                const aLink = document.createElement('a')
                aLink.href = url
                // 2.直接使用自定义文件名,设置下载文件名称
                aLink.setAttribute('download', fileName )
                document.body.appendChild(aLink)
                // 模拟点击下载
                aLink.click()
                // 移除改下载标签
                document.body.removeChild(aLink);
            })
            .catch(err => {
                console.log(err);
            });
    }
};
