<template>
  <el-row>
    <el-form class="demo-form-inline" :inline="true" :model="param">
      <el-form-item v-for="item in fields" :key="item.value" :label="item.label">
        <!-- 普通输入框 -->
        <el-input v-if="!item.type || item.type == 'string'" v-model="param[item.value]" 
          :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label" size="small" clearable />

        <!-- 下拉框 -->
        <el-select v-if="item.type == 'selector'" v-model="param[item.value]" size="small"
          :placeholder="item.placeholder ? item.placeholder : '请选择' + item.label" 
          filterable clearable>
          <el-option v-for="i in item.options" :key="item.optionsValue ? i[item.optionsValue] : i.value"
            :label="item.optionsLabel ? i[item.optionsLabel] : i.label"
            :value="item.optionsValue ? i[item.optionsValue] : i.value">
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item>
        <el-tooltip content="查询">
          <el-button type="primary" icon="el-icon-search" circle @click="search(param)" size="small"></el-button>
        </el-tooltip>
        <el-tooltip content="重置">
          <el-button icon="el-icon-refresh" circle @click="clear()" size="small"></el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
export default {
  props: ["fields", "search"],
  data() {
    return {
      param: {},
    };
  },
  mounted() { },
  methods: {
    clear() {
      this.$emit("clear","重置参数");
      this.param = {};
      this.search(null);
    },
  },
};
</script>

<style>
</style>