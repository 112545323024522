// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/ai';

// 定义后台接口地址
const url = {
    chat: baseUrl + '/chat/send',
    createChat: baseUrl + '/chat/create',
    history: baseUrl + '/chat/history',
    historyDetail: baseUrl + '/chat/history/detail',
}

// 对外导出操作方法
export function chat(param) {
    return request.post(url.chat, param);
}
export function createChat() {
    return request.post(url.createChat);
}
export function history() {
    return request.get(url.history);
}
export function historyDetail(historyId) {
    return request.get(url.historyDetail + '/' + historyId);
}