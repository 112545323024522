<template>
  <div id="app">
    <!-- <vue-particles 
      color="#fff" 
      :particleOpacity="0.8" 
      :particlesNumber="100" 
      shapeType="circle" 
      :particleSize="5"
      linesColor="#fff" 
      :linesWidth="1" 
      :lineLinked="true" 
      :lineOpacity="0.6" 
      :linesDistance="150" 
      :moveSpeed="2"
      :hoverEffect="true" 
      hoverMode="grab" 
      :clickEffect="true" 
      clickMode="push" 
      class="lizi" /> -->
    <router-view />
    <div class="bruce">
      <ul class="bubble-bgwall">
        <li v-for="i in 10" :key="i"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  watch:{
    "$store.state.system.name": {
      handler(value){
        document.title = value;
      }
    }
  },
  beforeCreate(){
    this.$api.system.getNameAndDeveloper().then(res=>{
      this.$store.state.system.name = res.data.name;
      this.$store.state.system.developer = res.data.developer;
    })
  },
  created() {
    document.title = this.$store.state.system.name;
    this.getDict();
  },
  mounted() {
    // 全局禁止右键菜单
    window.addEventListener('contextmenu', function (event) {
      event.preventDefault();
    }, false);
    // 限制F12和快捷键
    document.addEventListener('keydown', function (event) {
      let ctrlKey = event.ctrlKey; // Ctrl and Control
      let shiftKey = event.shiftKey // Shift
      let altKey = event.altKey // Alt or Option
      let metaKey = event.metaKey // Command
      let winKey = event.keyCode === 91 || event.keyCode === 92 // Win
      if (event.keyCode === 123 || // 禁用F12
        (ctrlKey && shiftKey && event.keyCode === 73) || // windows | Google Chrome | Ctrl + Shift + I
        (ctrlKey && shiftKey && event.keyCode === 74) || // windows | Google Chrome | Ctrl + Shift + J
        (ctrlKey && shiftKey && event.keyCode === 67) || // windows | Google Chrome | Ctrl + Shift + C
        (metaKey && altKey && event.keyCode === 73) || // macos | Google Chrome | Cmd + Option + I
        (metaKey && altKey && event.keyCode === 74) || // macos | Google Chrome | Cmd + Option + J
        (metaKey && altKey && event.keyCode === 67) || // macos | Google Chrome | Cmd + Option + C
        (metaKey && altKey && event.keyCode === 85) || // macos | Google Chrome | Cmd + Option + U
        (metaKey && shiftKey && event.keyCode === 67)    // macos | Google Chrome | Cmd + Shift + C
      ) {
        event.preventDefault();
      }
    });
  },
  methods: {
    getDict() {
      this.$api.dict.getAll().then(res=>{
        this.$store.state.dict = res.data
      })
    }
  }
};
</script>


<style lang="less">
@import './assets/css/background.css';

* {
  ::-webkit-scrollbar {
    background: linear-gradient(to bottom);
    width: 1px;
    height: 5px;
  }

  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6));
    border-radius: 3px;
  }

  margin: 0;
  padding: 0;
  font-size: 14px;
  //页面全灰
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: grayscale(100%);
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

body {
  padding: 0;
}

#app {
  height: auto;
  font-family: "仿宋";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;

  .lizi {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
