// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/base/data';

// 定义后台接口地址
const url = {
    list: baseUrl + "/list",
    detail: baseUrl + "/detail",
    del: baseUrl + "/del",
    save: baseUrl + "/save",
}

// 对外导出操作方法
export function list(param) {
    return request.post(url.list, param);
}
export function detail(param) {
    return request.post(url.detail, param);
}
export function del(param) {
    return request.post(url.del, param);
}
export function save(param) {
    return request.post(url.save, param);
}