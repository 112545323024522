// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/sys/user';

// 定义后台接口地址
const url = {
    save: baseUrl + '/save',
    list: baseUrl + '/list',
    reset: baseUrl + '/password/reset',
    editPassword: baseUrl + '/password/edit',
    profile: baseUrl + '/profile',
}

// 对外导出操作方法
export function reset(param){
    return request.post(url.reset, param)
}
export function save(param){
    return request.post(url.save, param);
}
export function getList(param){
    return request.post(url.list, param);
}
export function profile(){
    return request.post(url.profile);
}
export function editPassword(param){
    return request.post(url.editPassword, param);
}