// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/sys/resource';

// 定义后台接口地址
const url = {
    groupList: baseUrl + '/group/list',
    list: baseUrl + '/list',
    del: baseUrl + '/del',
    batchDel: baseUrl + '/del/batch',
    picList: baseUrl + '/list/pic',
    uploadFile: baseUrl + '/upload/file',
}

// 对外导出操作方法
export function getGroupList() {
    return request.get(url.groupList);
}

export function getList(param) {
    return request.post(url.list, param);
}

export function getPicList(param) {
    return request.get(url.picList, param);
}

export function del(param) {
    return request.post(url.del, param);
}

export function batchDel(param) {
    return request.post(url.batchDel, param);
}

export function uploadFile(formData) {
    return request.upload(url.uploadFile, formData);
}