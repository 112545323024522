// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/sys/menu';

// 定义后台接口地址
const url = {
    getMenuTree: baseUrl + '/tree',
    getMenuTreeAll: baseUrl + '/tree/all',
    getMenuList: baseUrl + '/list',
    getMenuOne: baseUrl,
    saveMenu: baseUrl + '/save',
    delMenu: baseUrl + '/del',
    getPermissions: baseUrl + '/permissions',
}

// 对外导出操作方法
//查询菜单树
export function getPermissions(param) {
    return request.get(url.getPermissions, param);
}
export function getMenuTree() {
    return request.get(url.getMenuTree);
}
export function getMenuTreeAll() {
    return request.get(url.getMenuTreeAll);
}
//查询菜单列表
export function getList(){
    return request.get(url.getMenuList);
}
//查询单个菜单
export function getOne(uuid){
    return request.get(url.getMenuOne + "/" + uuid);
}
//保存菜单
export function save(param){
    return request.post(url.saveMenu, param);
}
//删除菜单
export function del(param){
    return request.post(url.delMenu, param);
}