<template>
  <div>
    <!-- 功能按钮 -->
    <FunctionButton :permissions="permissions" :add="add" :exports="exports" :delDisabled="delDisabled" :batchDel="batchDelFunction"></FunctionButton>
    
    <!-- 表格 -->
    <el-table
      :data="list"
      :header-cell-style="headStyle"
      :cell-style="rowStyle"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- <el-table-column type="index" label="序号"></el-table-column> -->
      <el-table-column v-for="field in fields" :key="field.value" :prop="field.value" :label="field.label" >
        <template slot-scope="scope">
          
          <!-- 文本 -->
          <span v-if="!field.type || field.type=='' || field.type=='string'">
            <span class="text">{{scope.row[field.value]}}</span>
          </span>
          
          <!-- 布尔图标 -->
          <span v-if="field.type=='boolean'">
            <i v-if="scope.row[field.value]" class="el-icon-success" style="color:#13ce66"></i>
            <i v-if="!scope.row[field.value]" class="el-icon-error" style="color:#ff4949"></i>
            <!-- <el-switch v-model="scope.row[field.value]" active-color="#13ce66" inactive-color="#ff4949" disabled/> -->
          </span>

          <!-- 图片 -->
          <img v-if="field.type=='image'" :src="scope.row[field.value]" height="30px" alt=""/>
          
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <slot name="buttonBeforeSlot" :row="scope.row"></slot>
          <el-tooltip content="编辑" class="operatingButton">
            <el-link
              type="primary"
              icon="el-icon-edit-outline"
              :underline="false"
              v-if="permissions.update"
              @click="edit(scope.row.uuid)"
            ></el-link>
          </el-tooltip>
          <el-tooltip content="删除" class="operatingButton">
            <el-popconfirm
              title="是否确认删除这条记录？"
              confirm-button-text="是"
              cancel-button-text="否"
              @confirm="del(scope.row.uuid)"
              v-if="permissions.delete"
            >
              <el-link
                type="danger"
                icon="el-icon-delete-solid"
                :underline="false"
                slot="reference"
              ></el-link>
            </el-popconfirm>
          </el-tooltip>
          <slot name="buttonSlot" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["list", "fields", "permissions", "add", "edit", "del", "batchDel", "exports"],
  data() {
    return {
      multipleSelection: [],
      delDisabled: true,
    };
  },
  mounted() {},
  methods: {
    batchDelFunction(){
      let uuids = [];
      this.multipleSelection.forEach(e=>{
        uuids.push(e.uuid);
      })
      this.batchDel(uuids);
    },
    handleSelectionChange(val) {
      if(val.length == 0){
        this.delDisabled = true;
      }else{
        this.delDisabled = false;
      }
      this.multipleSelection = val;
    },
    // 表头样式
    headStyle() {
      return "text-align:center;background:rgb(248,248,249)";
    },
    // 单元格样式
    rowStyle() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="less">
.operatingButton{
  margin: 0 5px;
}
.text{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
</style>