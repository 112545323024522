// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/system';

// 定义后台接口地址
const url = {
    getNameAndDeveloper: baseUrl + '/config/nameAndDeveloper',
}

// 对外导出操作方法
export function getNameAndDeveloper() {
    return request.get(url.getNameAndDeveloper);
}