// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/dict';

// 定义后台接口地址
const url = {
    save: baseUrl + '/save',
    del: baseUrl + '/del',
    saveDetail: baseUrl + '/detail/save',
    delDetail: baseUrl + '/detail/del',
    get: baseUrl + '/get',
    getAll: baseUrl + '/all',
}

// 对外导出操作方法
export function save(param) {
    return request.post(url.save, param);
}
export function del(param) {
    return request.post(url.del, param);
}
export function saveDetail(param) {
    return request.post(url.saveDetail, param);
}
export function delDetail(param) {
    return request.post(url.delDetail, param);
}
export function getDict(dictCode) {
    return request.get(url.get + "/" + dictCode);
}
export function getDictValue(dictCode, dictLabel) {
    return request.get(url.get + "/" + dictCode + "/" + dictLabel);
}
export function getAll() {
    return request.get(url.getAll);
}