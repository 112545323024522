import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import api from './api'
import utils from './utils/utils.js'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';

import DataTable from '@/views/Common/DataTable'
import Form from '@/views/Common/Form'
import FunctionButton from '@/views/Common/FunctionButton'
import Search from '@/views/Common/Search'
import VueParticles from 'vue-particles'; 

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils

Vue.use(ElementUI)
Vue.use(VueParticles);
Vue.component('DataTable', DataTable)
Vue.component('Form', Form)
Vue.component('FunctionButton', FunctionButton)
Vue.component('Search', Search)

import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.use(mavonEditor);


new Vue({
  router,
  api,
  utils,
  store,
  render: h => h(App)
}).$mount('#app')
