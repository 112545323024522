// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/base/list/config';

// 定义后台接口地址
const url = {
    generatePage: baseUrl +"/generate/page"
}

// 对外导出操作方法
export function getConfigByUuid(uuid) {
    return request.get(baseUrl + "/" + uuid);
}
export function generatePage(param) {
    return request.post(url.generatePage, param);
}