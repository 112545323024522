//导入其他api文件
import * as auth  from './auth';
import * as menu  from './menu';
import * as baseObj  from './baseObj';
import * as baseObjField  from './baseObjField';
import * as online  from './online';
import * as baseData  from './baseData';
import * as baseListConfig  from './baseListConfig';
import * as dept  from './dept';
import * as user  from './user';
import * as resource  from './resource';
import * as upload  from './upload';
import * as dict  from './dict';
import * as ai  from './ai';
import * as system  from './system';
import * as other  from './other';

export default {
    auth,
    menu,
    baseObj,
    baseObjField,
    online,
    baseData,
    baseListConfig,
    dept,
    user,
    resource,
    upload,
    dict,
    ai,
    system,
    other,
}
