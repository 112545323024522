// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/auth';

// 定义后台接口地址
const url = {
    login: baseUrl + '/login',
    logout: baseUrl + '/logout',
    getCode: baseUrl + '/getCode',
    showToken: baseUrl + '/showToken',
}

// 对外导出操作方法
export function login(param) {
    return request.post(url.login, param);
}
export function logout(param) {
    return request.post(url.logout, param);
}
export function getCode() {
    return request.get(url.getCode);
}
export function showToken() {
    return request.get(url.showToken);
}