<template>
  <el-row style="margin-bottom: 10px">
    <el-tooltip content="新增">
      <el-button
        v-if="permissions.insert"
        icon="el-icon-plus"
        type="primary"
        circle
        @click="add()"
        size="small"
      ></el-button>
    </el-tooltip>
    <el-tooltip content="导出">
      <el-button
        v-if="permissions.export"
        icon="el-icon-download"
        type="warning"
        circle
        @click="exports()"
        size="small"
      ></el-button>
    </el-tooltip>
    <el-tooltip content="批量删除">
      <el-button
        v-if="permissions.delete"
        icon="el-icon-delete"
        type="danger"
        circle
        @click="batchDel()"
        size="small"
        :disabled="delDisabled"
      ></el-button>
    </el-tooltip>
    <slot name="functionButton"></slot>
  </el-row>
</template>

<script>
export default {
  props: ["permissions", "add", "exports","delDisabled", "batchDel"],
  mounted() {},
};
</script>

<style>
</style>