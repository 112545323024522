import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/admin',
    redirect: '/admin/index',
    name: 'Index',
    component: resolve => (require(["@/views"], resolve)),
    children:[
      {
        path: 'index',
        name: 'Home',
        component: resolve => (require(["@/views/Home"], resolve))
      },
      {
        path: 'platform/baseObj',
        name: 'BaseObj',
        component: resolve => (require(["@/views/Platform/BaseObj"], resolve))
      },
      {
        path: 'platform/basePage/config',
        name: 'BasePageConfig',
        component: resolve => (require(["@/views/Platform/BasePageConfig"], resolve))
      },
      {
        path: 'platform/dict',
        name: 'Dict',
        component: resolve => (require(["@/views/Platform/Dict"], resolve))
      },
      {
        path: 'sys/user',
        name: 'SysUser',
        component: resolve => (require(["@/views/System/User"], resolve))
      },
      {
        path: 'sys/menu',
        name: 'SysMenu',
        component: resolve => (require(["@/views/System/Menu"], resolve))
      },
      {
        path: 'sys/dept',
        name: 'SysDept',
        component: resolve => (require(["@/views/System/Dept"], resolve))
      },
      {
        path: 'sys/resource',
        name: 'Resource',
        component: resolve => (require(["@/views/System/Resource"], resolve))
      },
      {
        path: 'sys/log/operation',
        name: 'OperationLog',
        component: resolve => (require(["@/views/System/Log/Operation"], resolve))
      },
      {
        path: 'monitoring/online',
        name: 'OnlineUsers',
        component: resolve => (require(["@/views/Monitoring/Online"], resolve))
      },
      {
        path: 'list/:objCode/:uuid',
        name: 'OnlineUsers',
        component: resolve => (require(["@/views/Base/List"], resolve))
      },
      {
        path: 'swagger',
        name: 'Swagger',
        component: resolve => (require(["@/views/Tools/Swagger"], resolve))
      },
      {
        path: 'gpt',
        name: 'GPT',
        component: resolve => (require(["@/views/GPT"], resolve))
      },
      {
        path: 'gpt/history',
        name: 'GPTHistory',
        component: resolve => (require(["@/views/GPT/history.vue"], resolve))
      },
    ]
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: resolve => (require(["@/views/Login"], resolve))
  },

]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history', // 去掉url中的#   history | hash
  scrollBehavior: () => ({ y: 0 }),
  routes
})
